import {Component, Input, OnInit} from '@angular/core';
import {Element} from "../../../_models/element.model";

@Component({
    selector: 'manager-loader-element',
    templateUrl: './loader_element.component.html',
    standalone: false
})
export class LoaderElementComponent implements OnInit {
  @Input() element: Element;
  @Input() table: boolean = false;

  constructor() {}

  ngOnInit() {}
}
