import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class LogService {
  url = environment.api_url;

  constructor() {}

  log(msg: any) {
    console.log(msg);
  }

  log_dev(msg: any) {
    if (!environment.production) {
      console.log(msg);
    }
  }

  log_construct(type: string = null, name: string = null) {
    if (environment.console_log_construct) {
      let color, background_color = null;
      if (!type) {
        type = name.split('.').pop();
      }
      switch (type) {
        case 'component':
          color = '#008126';
          background_color = '#b1b1b1';
          break;
        case 'service':
          color = '#0043dd';
          background_color = '#b1b1b1';
          break;
        default:
          color = null;
          background_color = null;
      }
      console.log('%c ----- CONSTRUCT > ' + type.toUpperCase() + ' > ' + name + ' ----- ', 'background: ' + background_color + '; color: ' + color);
    }
  }

  log_light(msg) {
    let color, background_color = null;
    color = '#d2ccda';
    background_color = '#271da4';
    console.log('%c' + msg, 'background: ' + background_color + '; color: ' + color);
  }

  log_response_error(errors) {
    if (errors && environment.console_log_error) {
      let message = '';
      let code = '';
      let color, background_color = null;
      color = '#d2ccda';
      background_color = '#a4000c';

      if (errors.isArray) {  // Several errors
        errors.forEach(e => {
          message += e.message + '\n';
        });

      } else if (typeof errors === 'object') {  // Only One
        message = errors.message;
        code = errors.code;

      } else { }

      console.log('%c__RESPONSE-ERROR__  ' + (code ? '#' + code + ' > ' : '') + message + '  __RESPONSE-ERROR__', 'background: ' + background_color + '; color: ' + color);
    }
  }

  warn(msg: any) {
    console.warn(msg);
  }

  warn_dev(msg: any) {
    if (!environment.production) {
      console.warn(msg);
    }
  }

  error(msg: any) {
    console.error(msg);
  }

  error_dev(msg: any) {
    if (!environment.production) {
      console.error(msg);
    }
  }

  alert(msg: any) {
    alert(msg);
  }

  alert_dev(msg: any) {
    if (!environment.production) {
      alert(msg);
    }
  }

  alert_response_error(errors) {
    if (environment.alert_error) {
      let msg = '';
      let color, background_color = null;
      color = '#bada55';
      background_color = '#222';

      if (errors.isArray) {  // Several errors
        errors.forEach(e => {
          msg += e.message + '\n';
        });

      } else if (typeof errors === 'object') {  // Only One
        msg = errors.message;

      } else { }

      alert(msg);
    }
  }
}
