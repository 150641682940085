import {Component, OnInit, AfterViewInit, Input, OnChanges, ViewChild, OnDestroy, SimpleChanges} from '@angular/core';
import {fadeInOnEnterAnimation, fadeOutOnLeaveAnimation} from "angular-animations";
import {ListService} from "../../_services/list.service";
import {SiteModule} from "../../_models/site_module.model";
import {AppService} from "../../_services/app.service";
import {Action} from "../../_models/action.model";
import {Detail} from "../../_models/detail.model";
import {GridListRow} from "../../_models/grid_list_row.model";
import {ListRow} from "../../_models/list_row.model";
import {LogService} from "../../_services/log.service";

@Component({
    selector: 'manager-wrapper',
    templateUrl: './wrapper.component.html',
    animations: [
        fadeInOnEnterAnimation(),
        fadeOutOnLeaveAnimation(),
    ],
    standalone: false
})
export class WrapperComponent implements OnInit, OnChanges, OnDestroy {
    @Input() datas;
    @Input() dropdown;
    //
    type: string;
    wrapper: string;
    action: Action;
    file;
    ressource: SiteModule|Element|Detail|GridListRow|ListRow;

    constructor(
        public appS: AppService,
    ) {}

    ngOnInit(): void {
        this.appS.logS.log_construct('component', 'WRAPPER #' + this.datas['type'] + '.' + this.datas['wrapper']);
        // INIT
        this.type = this.datas['type'];
        this.wrapper = this.datas['wrapper'];
        this.action = this.datas['action'];
        this.file = this.datas['file'];
        // this.ressource = this.datas['action'];
    }

    ngOnChanges() {}

    ngOnDestroy() {
        // RESET STRUCTURE
        this.appS.structure.reset(this.type, this.wrapper);
        // RESET NAVIGATION
        this.appS.navigationS.remove(this.type, this.wrapper);
        // WRAPPER
        this.appS._wrappers[this.type + '.' + this.wrapper]?.unsubscribe();
    }
}
