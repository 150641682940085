import {Component, Input, OnInit} from '@angular/core';
import {AppService} from "../../../_services/app.service";

@Component({
    selector: 'manager-loader-wrapper-modal',
    templateUrl: './loader_wrapper_modal.component.html',
    standalone: false
})
export class LoaderWrapperModalComponent implements OnInit {
  @Input() type: string;
  @Input() wrapper: string;
  //
  @Input() header: boolean;
  @Input() footer: boolean;

  constructor(
      public appS: AppService,
  ) {}

  ngOnInit() {}

}
