import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'manager-loader',
    templateUrl: './loader.component.html',
    standalone: false
})
export class LoaderComponent implements OnInit {
  @Input() type: string;
  @Input() wrapper: string;

  constructor() {}

  ngOnInit() {}
}
