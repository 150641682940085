import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import {map, catchError, switchMap, finalize, concatMap, mergeMap, tap} from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {environment} from "../../environments/environment";
import {UserModel} from "../component/auth/_models/user.model";
import {Functions} from "../_functions/functions";
import {RestService} from "./rest.service";
import {LogService} from "./log.service";

const API_URL = `${environment.api_url}user`;
const LOCAL_STORAGE_TOKEN = `SOSF-${environment.version}-${environment.local_storage_token}`;

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  token: string;
  user: UserModel;
  // IP
  init_need_ip: boolean;
  ip_wan: string;
  ip_lan: string[];
  init$: Observable<any>;
  ip_wan$: Observable<string>;
  ip_lan$: Observable<string[]>;

  constructor(
      private http: HttpClient,
      private router: Router,
      private logS: LogService,
  ) {
    this.logS.log_construct('service', 'AUTH');

    // INIT
    this.http.get(API_URL + '/init').pipe(
        tap((response: any) => {
          this.init_need_ip = response.need_ip
        }),
        switchMap((response: any) => {
          if (this.init_need_ip) {
            return this.getIPs();
          } else {
            return of(null);
          }
        })
    ).subscribe();

    // IP WAN
    this.ip_wan$ = this.http.get("https://api.ipify.org/?format=json").pipe(
        tap((response: any) => { this.ip_wan = response.ip })
    );

    // IP LAN
    this.ip_lan$ = new Observable(observer => {
      this.ip_lan = [];
      if (window.RTCPeerConnection) {
        const ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g;
        var myPeerConnection = window.RTCPeerConnection;
        var pc = new myPeerConnection({iceServers:[]})
        var noop = function(){};

        pc.createDataChannel('');
        pc.createOffer().then(function(sdp) {
          pc.setLocalDescription(sdp, noop, noop);
        }).catch(function(reason) {});

        // listen for candidate events
        pc.onicecandidate = function(ice) {
          if (ice && ice.candidate && ice.candidate.candidate && ice.candidate.candidate.match(ipRegex)) {
            ice.candidate.candidate.match(ipRegex).forEach(ip => {
              if (!this.ip_lan.includes(ip)) {
                this.ip_lan.push(ip);
              }
            });
          }
        }.bind(this);
        Functions.completeObservable(observer);

      } else {
        console.log('NO IP');
        Functions.completeObservable(observer);
      }
    });
  }

  ngOnDestroy() {}

  /* ---------------------------------------------------------------------------------------------------------------- */

  login(email: string, password: string, site: string = null, url: string = null): Observable<any> {
    return this.http.post(API_URL + '/connexion', JSON.stringify({username: email, password: password, site_id: site, url: url, ip_wan: this.ip_wan, ip_lan: JSON.stringify(this.ip_lan)}))
        .pipe(
            tap((response: any) => {
              if (response && response.token) {
                // SAVE AUTH
                localStorage.setItem(LOCAL_STORAGE_TOKEN, JSON.stringify(response.token));
              }
            })
        );
  }

  logout() {
    if (this.token) {
      this.http.post<{success: boolean; url?: string}>(API_URL + '/deconnexion', null, {headers: new HttpHeaders({Authorization: `Bearer ${this.token}`})}).subscribe({
          next: (response) => {
            if (response.success) {
              this.resetAuth(response.url);
            } else {
              this.resetAuth();
            }
          }
      });
    } else {
      this.resetAuth();
    }
  }

  /* ---------------------------------------------------------------------------------------------------------------- */

  getUser(): Observable<UserModel> {
    if (this.user) {
      return of(this.user);
    }

    this.token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_TOKEN));
    // GET USER CONNECTED FROM TOKEN
    if (this.token) {
      return this.http.post<UserModel>(API_URL, JSON.stringify({ip_wan: this.ip_wan, ip_lan: JSON.stringify(this.ip_lan)}), {headers: new HttpHeaders({Authorization: `Bearer ${this.token}`})}).pipe(
          map((user: UserModel) => {
            if (user) {
              this.user = user;
            } else {
              this.logout();
            }
            return user;
          }));
    }

    return of(null);
  }

  resetAuth(url: string = '/auth/connexion') {
    // RESET AUTH
    this.token = null;
    // RESET USER
    this.user = null;
    // REMOVE LOCAL STORAGE
    localStorage.removeItem(LOCAL_STORAGE_TOKEN);
    // ROUTER
    this.router.navigate([url]);
  }

  /* ---------------------------------------------------------------------------------------------------------------- */

  private getIPs(): Observable<void> {
    return new Observable((observer) => {
      // Exécuter les deux observables
      this.ip_wan$.subscribe({
        next: () => {
          this.ip_lan$.subscribe({
            next: () => observer.complete(),
            error: (err) => observer.error(err),
          });
        },
        error: (err) => observer.error(err),
      });
    });
  }
}
