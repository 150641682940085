import {Injectable, NgZone} from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Location } from '@angular/common';
import {NavigationModalService} from './navigation.modal.service';
import {Navigation} from "../_models/navigation.model";
import {NavigationOffcanvasService} from "./navigation.offcanvas.service";
import {WrapperComponent} from "../layout/0_wrapper/wrapper.component";
import {AppService} from "./app.service";
import {LogService} from "./log.service";
import {Action} from "../_models/action.model";
import {Detail} from "../_models/detail.model";
import {SiteModule} from "../_models/site_module.model";

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  navigations_: BehaviorSubject<Navigation>[] = [];
  navigations: {} = {};
  //
  appS: AppService;
  key_ctrl = false;

  constructor(
      public router: Router,
      //
      private logS: LogService,
      private modalS: NavigationModalService,
      private offcanvasS: NavigationOffcanvasService,
  ) {
    this.logS.log_construct('service', 'NAVIGATION');

    // CHECK KEY PRESSED
    document.addEventListener('keydown', (event) => {
      // CTRL
      if (event.which === 17){
        this.key_ctrl = true;
      }
      // CMD
      if (event.which === 91){
        this.key_ctrl = true;
      }
    });
    document.addEventListener('keyup', (event) => {
      this.key_ctrl = false;
    });
  }

  /** --------------------------------------------------------------------------------------------------------------- */

  setAppS(appS: AppService) {
    this.appS = appS;
  }

  /** --------------------------------------------------------------------------------------------------------------- */

  // NAVIGATION
  get(name: string) {
    return this.navigations_[name];
  }
  init(type: string, wrapper: string, route: string[], step: string = 'A1') {
    this.appS.logS.log_dev('INIT NAVIGATION ' + type + '.' + wrapper );
    this.navigations[type + '.' + wrapper] = new Navigation({type: type, wrapper: wrapper, route: typeof route !== "string" ? route : null, route_full: typeof route === "string" ? route : null, step: step});
  }
  remove(type: string, wrapper: string) {
    delete this.navigations[type + '.' + wrapper];
    delete this.navigations_[type + '.' + wrapper];
  }

  /** --------------------------------------------------------------------------------------------------------------- */

  navigate(type: string, wrapper: string, step: string, route: string[], anchor: string = null, reload: boolean = false, ressource = null, options = null, datas: {} = {}, menu: SiteModule = null): void {
    if (route && route[0] === '') {
      route.shift();
    }
    console.warn('NAVIGATE', type, wrapper, step, route, anchor, reload, ressource, options, datas);

    // OPTIONS
    if (type === 'modal') {
      options = { ...this.modalS.options_default, modalDialogClass: options?.size ?? ressource?.child_site_module?.size};
    }
    else if (type === 'offcanvas') {
      options = { ...this.offcanvasS.options_default, panelClass: options?.size ?? ressource?.child_site_module?.size};
      // console.log(options);
    }

    if (!this.navigations[type + '.' + wrapper]) {
      this.init(type, wrapper, route);
      switch (type) {
        case 'modal':
          this.modalS.open(wrapper, WrapperComponent, options ?? null, datas);
          this.modalS.modal[wrapper]['custom_size'] = !!options?.modalDialogClass;
          break;
        case 'offcanvas':
          this.offcanvasS.open(wrapper, WrapperComponent, options ?? null, datas);
          console.log(options);
          this.offcanvasS.offcanvas[wrapper]['custom_size'] = !!options?.panelClass;
          break;
        case 'dropdown':
          break;
        case 'element':
          // setTimeout(() => {this.router.navigate(this.managerAnchor(anchor));},0); // NEEDED BECAUSE IF SEVERAL ELEMENT IN PAGE, GET ALL ANCHORS
          break;
      }
    }
    // else {
    // NEW TAB
    if (type === 'page' && this.key_ctrl) {
      type = 'tab';
    } else {
      this.navigations[type + '.' + wrapper].update({route: typeof route !== 'string' ? route : null, route_full: typeof route === 'string' ? route : null, step: step, anchor: anchor});
    }

    switch (type) {
      case 'tab':
        if (!route) {
          return;
        }
        let url;
        if (typeof route === 'string') {
          url = route;
        } else {
          url = this.router.serializeUrl(this.router.createUrlTree(route));
        }
        window.open(url, '_blank');
        return;
      case 'page':
        if (reload) {
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
        // CLOSE ALL MODAL
        this.modalS.closeAll();
        // CLOSE ALL OFFCANVAS
        this.offcanvasS.closeAll();
        break;
      case 'element':
        setTimeout(() => { this.router.navigate(this.managerAnchor(anchor)); }, 0); // NEEDED BECAUSE IF SEVERAL ELEMENT IN PAGE, GET ALL ANCHORS
        break;
    }
    // }

    // CHANGE BROWSER URL
    if (type === 'page' && wrapper === 'main') {
      this.router.navigate(this.navigations[type + '.' + wrapper].route);
    }

    // REFRESH WRAPPER
    this.appS.refreshWrapper(type, wrapper);
  }

  /** --------------------------------------------------------------------------------------------------------------- */

  managerAnchor(anchor) {
    const path = this.router.url.split('/');
    if (anchor) {
      // GET ANCHORS
      let anchors = {};
      const parts = path[path.length - 1].split('#');
      path[path.length - 1] = parts.shift();
      parts.forEach(part => {
        const a = part.split('.');
        console.log(a);
        anchors[a[0]] = a[1];
      });
      // REPLACE IF NEEDED
      const b = anchor.split('.');
      console.log(b);
      anchors[b[0]] = b[1];
      // ADD TO BASE
      Object.keys(anchors).forEach(key => {
        path[path.length - 1] += '#' + key + '.' + anchors[key];
        console.log(path);
      });
    }
    return path;
  }
}
