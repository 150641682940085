import {Detail} from "./detail.model";
import {Type} from "./type.model";
import {List} from "./list.model";
import {Action} from "./action.model";
import {Grid} from "./grid.model";
import {GridList} from "./grid_list.model";
import {ChildSiteModule} from "./child_site_module.model";
import {GridListRow} from "./grid_list_row.model";
import {ListRow} from "./list_row.model";

export class Element {
  unique_id: string;
  ressource_id: string;
  entity_id: string;
  //
  id: string;
  label: string;
  icon: string;
  order: number;
  show_title: boolean = true;
  border: boolean;
  collapsed: boolean;
  exported: boolean;
  pagination: number;
  bloc_size: number;
  wrapper: string;
  // MANY TO ONE
  type: Type;
  list_type: Type;
  child_site_module: ChildSiteModule;
  // ONE TO MANY
  grids: Grid[];
  grid_rows: GridListRow[];
  rows: ListRow[];
  details: {} = {};
  options: {} = {};
  actions: Action[];
  // LIST
  list: List;
  grid_list: GridList;
  // BUBBLE
  bubble_nb: number;
  bubbles_for_sitemodule: [] = [];
  // CUSTOM
  display: boolean;
  // ACTION
  action_active: boolean = false;
  // LOADING
  loading: boolean = false;
  loading_refresh: boolean = false;

  constructor(datas) {
    // RESSOURCE ID
    this.ressource_id = datas['entity_id'] ?? null;
    this.unique_id = 'element.' +  datas['id'] + (this.ressource_id ? '#' + this.ressource_id : '');
    // OTHER
    this.update(datas);
  }

  /** --------------------------------------------------------------------------------------------------------------- */

  update(datas) {
    Object.assign(this, datas);
    // TYPE
    this.type = new Type(this.type);
    if (this.child_site_module) {
      this.child_site_module = new ChildSiteModule(this.child_site_module);
    }
    // DETAILS
    if (datas.sub_details) {
      this.details = {};
      Object.keys(datas.sub_details).forEach(key_detail => {
        let details = [];
        let main_detail = null;
        Object.keys(datas.sub_details[key_detail]).forEach(key_subdetail => {
          let detail = datas.sub_details[key_detail][key_subdetail];
          // MAIN SUB DETAIL
          if (key_subdetail === key_detail) {
            main_detail = detail;
          }
          details[detail.placement ?? key_subdetail] = (new Detail(detail, this, this.ressource_id));
        });
        this.details[main_detail.placement ?? key_detail] = details;
      });
    }
    console.log(this.details)
    // LIST
    if (this.type.name === 'list' && this.list_type) {
      this.list = new List(datas.rows, this);
    }
    this.rows = null;
    // GRID LIST
    if (this.type.name === 'list' && !this.list_type) {
      this.grid_list = new GridList(datas.grid_rows, this);
    }
    this.grid_rows = null;
    // PAGINATION
    if (this.pagination !== 0) {
      if (this.list) {
        this.list.updatePageInterval();
      }
      if (this.grid_list) {
        this.grid_list.updatePageInterval();
      }
    }
    // OPTIONS
    if (datas?.options) {
      this.options = {};
      datas.options.forEach(option => {
        this.options[option.name] = option.value;
      });
    }
    // ACTIONS
    if (datas?.actions) {
      this.actions = [];
      datas.actions.forEach(action => {
        this.actions.push(new Action(action, this));
      });
    }
  }

  /** --------------------------------------------------------------------------------------------------------------- */

  delete() {
    this.type = null;
    this.list_type = null
    this.child_site_module = null;
    //
    this.grids?.forEach(item => {
      item.delete();
    });
    this.grids = null;
    //
    this.grid_rows?.forEach(item => {
      item.delete();
    });
    this.grid_rows = null;
    //
    this.rows?.forEach(item => {
      item.delete();
    });
    this.rows = null;
    //
    Object.keys(this.details)?.forEach(item => {
      this.details[item].delete();
    });
    this.details = null;
    //
    this.actions?.forEach(item => {
      item.delete();
    });
    this.actions = null;
  }
}
