import {Injectable, OnDestroy} from '@angular/core';
import {NgbOffcanvas, NgbOffcanvasRef} from "@ng-bootstrap/ng-bootstrap";
import {NgbOffcanvasOptions} from "@ng-bootstrap/ng-bootstrap/offcanvas/offcanvas-config";
import {NgbOffcanvasRefCustom} from "../_models/vendor/offcanvas";
import {first} from "rxjs/operators";
import {Subscription} from "rxjs";
import {LogService} from "./log.service";

@Injectable({
  providedIn: 'root'
})
export class NavigationOffcanvasService implements OnDestroy {
  public offcanvas: NgbOffcanvasRefCustom[] = [];
  //
  type: string = 'offcanvas';
  options_default: {} = {position: 'end', backdrop: true, keyboard: true, scroll: false, panelClass: 's-1'};
  //
  _offcanvas_closed: Subscription;
  _offcanvas_dismissed: Subscription;

  constructor(
      private offcanvasS: NgbOffcanvas,
      private logS: LogService,
  ) {
    this.logS.log_construct('service', 'NAVIGATION OFFCANVAS');
  }

  ngOnDestroy() {
    this._offcanvas_closed.unsubscribe();
    this._offcanvas_dismissed.unsubscribe();
  }

  /* ---------------------------------------------------------------------------------------------------------------- */

  open(wrapper: string, content: any, options: NgbOffcanvasOptions = this.options_default, datas: {} = {}) {
    options = options ?? this.options_default;
    if (this.offcanvas[wrapper] === undefined || !this.offcanvas[wrapper] || (datas['mobile'] && datas['mobile'] === true)) {
      console.log(wrapper + ' OPEN');
      // OPEN
      this.offcanvas[wrapper] = this.offcanvasS.open(content, options);
      this.offcanvas[wrapper].componentInstance.datas = datas;
      this.offcanvas[wrapper].componentInstance.datas['type'] = this.type;
      this.offcanvas[wrapper].componentInstance.datas['wrapper'] = wrapper;
      // this.offcanvas[wrapper]._panelCmptRef.instance.panelClass = this.site_module?.size ?? 's-5';
    }
  }

  closeAll() {
    Object.keys(this.offcanvas).forEach(key => {
      this.offcanvas[key].close();
    });
  }
}
