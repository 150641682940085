import {Injectable, OnDestroy} from '@angular/core';
import {NgbModal, NgbModalOptions, NgbModalRef, NgbOffcanvas, NgbOffcanvasRef} from "@ng-bootstrap/ng-bootstrap";
import {NgbOffcanvasOptions} from "@ng-bootstrap/ng-bootstrap/offcanvas/offcanvas-config";
import {NgbModalRefCustom} from "../_models/vendor/modal";
import {first} from "rxjs/operators";
import {Subscription} from "rxjs";
import {LogService} from "./log.service";


@Injectable({
  providedIn: 'root'
})
export class NavigationModalService implements OnDestroy {
  public modal: NgbModalRefCustom[] = [];
  //
  type: string = 'modal';
  options_default: {} = {backdrop: false, keyboard: true, scrollable: true, modalDialogClass: 'w-5'};
  //
  _modal_closed: Subscription;
  _modal_dismissed: Subscription;

  constructor(
      private modalS: NgbModal,
      private logS: LogService,
  ) {
    this.logS.log_construct('service', 'NAVIGATION MODAL');
  }

  ngOnDestroy() {
    this._modal_closed.unsubscribe();
    this._modal_dismissed.unsubscribe();
  }

  /* ---------------------------------------------------------------------------------------------------------------- */

  open(wrapper: string, content: any, options: NgbModalOptions = this.options_default, datas: {} = {}) {
    options = options ?? this.options_default;
    if (this.modal[wrapper] === undefined || !this.modal[wrapper]) {
      // ADD CUSTOM OPTIONS
      options['focusFirst'] = false;
      // OPEN
      this.modal[wrapper] = this.modalS.open(content, options);
      this.modal[wrapper].componentInstance.datas = datas;
      this.modal[wrapper].componentInstance.datas['type'] = this.type;
      this.modal[wrapper].componentInstance.datas['wrapper'] = wrapper;
    }
  }

  closeAll() {
    Object.keys(this.modal).forEach(key => {
      this.modal[key]?.close();
    });
  }

  /** --------------------------------------------------------------------------------------------------------------- */
}
