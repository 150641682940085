import {Component, Input, OnInit} from '@angular/core';
import {AppService} from "../../../_services/app.service";

@Component({
    selector: 'manager-loader-wrapper-page',
    templateUrl: './loader_wrapper_page.component.html',
    standalone: false
})
export class LoaderWrapperPageComponent {
  @Input() type: string;
  @Input() wrapper: string;

  constructor(
      public appS: AppService,
  ) {}
}
