import {Component, Input, OnInit} from '@angular/core';
import {Element} from "../../../_models/element.model";
import {ListRow} from "../../../_models/list_row.model";
import {GridListRow} from "../../../_models/grid_list_row.model";

@Component({
    selector: 'manager-loader-row',
    templateUrl: './loader_row.component.html',
    standalone: false
})
export class LoaderRowComponent implements OnInit {
  @Input() row: ListRow|GridListRow;

  constructor() {}

  ngOnInit() {}
}
