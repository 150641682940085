import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {FormArray, FormGroup, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {Section} from "../_models/form_section.model";
import {Field} from "../_models/form_field.model";
import {AutocompleteService} from "./autocomplete.service";
import {Action} from "../_models/action.model";
import moment from "moment";
import {LogService} from "./log.service";

@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor(
      private logS: LogService,
  ) {
    this.logS.log_construct('service', 'FORM');
  }

  /** --------------------------------------------------------------------------------------------------------------- */

  generateForm(action: Action, entity_id: string, sections: Section[], values: {}) {
    let _form = {};
    sections.forEach(section => {
      _form['section.' + section.id] = this.generateSection(entity_id, section, values['section.' + section.id]);
    });
    return new FormGroup(_form);
  }

  generateSection(entity_id: string, section: Section, values: {}, is_new: boolean = false) {
    let _section = {};
    if (is_new) {
      const nb = section.number_default ?? 1;
      for (let i = 0; i < nb; i++) {
        let key = 'loop.new.' + (i+1);
        // SECTION
        _section[key] = this.generateLoop(entity_id, section, key, null, is_new);
      }
    } else {
      // if (values) {}
      Object.keys(values).forEach(key => {
        // SECTION
        _section[key] = this.generateLoop(entity_id, section, key, values);
      });
    }
    return new UntypedFormGroup(_section);
  }

  generateLoop(entity_id: string, section: Section, key_loop: string, values: {} = null, is_new: boolean = false) {
    // this.app.log.log_dev(values);
    // FIELDS
    let fields = this.generateFields(entity_id, section.fields, key_loop, values && values[key_loop] ? values[key_loop]['fields'] : null);
    // SECTIONS
    let sections = {};
    section.sections.forEach(s => {
      // SECTION
      sections['section.' + s.id] = this.generateSection(entity_id, s, values && values[key_loop] ? values[key_loop]['sections']['section.' + s.id] : null, is_new);
    });

    return new UntypedFormGroup({'fields': fields, 'sections': new UntypedFormGroup(sections)});
  }

  // ADD FIELDS TO FROM GROUP
  generateFields(entity_id: string, fields: Field<any>[], key_loop: string, values: {} = null) {
    // GROUP
    const group: any = {};
    fields.forEach(field => {
      // PROPERTIES
      let updateOn = null;
      if (field.refresh_dynamic && field.type.name === 'input') {
        updateOn = 'blur';
      }
      // VALIDATORS
      const validators = [];
      field.checks?.forEach(check => {
        switch (check.type.type) {
          case 'required':
            validators.push(Validators.required);
            break;
          case 'required_true':
            validators.push(Validators.requiredTrue);
            break;
          case 'required_false':
            validators.push(!Validators.requiredTrue);
            break;
          case 'int':
            validators.push(Validators.pattern('^[0-9]*$'));
            break;
          case 'float':
            validators.push(Validators.pattern('^[0-9]*([.,][0-9]+)?$'));
            break;
          case 'string':
            validators.push(Validators.pattern('^[a-zA-Z]*$'));
            break;
          case 'string+':
            validators.push(Validators.pattern('^[a-zçàâæáäãāéèêëęėēûùüúūîïìíôœöòóõōÿA-ZÇÀÂÆÁÄÃÅĀÉÈÊËĘĖĒŸÛÙÜÚŪÎÏÌÍĪÔŒÖÒÓÕŌ \\-\\\']*$'));
            break;
          case 'alphanumeric':
            validators.push(Validators.pattern('^[a-zA-Z0-9]*$'));
            break;
          case 'alphanumeric+':
            validators.push(Validators.pattern('^[a-zçàâæáäãāéèêëęėēûùüúūîïìíôœöòóõōÿA-ZÇÀÂÆÁÄÃÅĀÉÈÊËĘĖĒŸÛÙÜÚŪÎÏÌÍĪÔŒÖÒÓÕŌ0-9 \\-\\\']*$'));
            break;
          case 'bool':
            validators.push(Validators.pattern('^(true|false|1|0)$'));
            break;
          case 'phone':
            validators.push(Validators.pattern('^(?:(?:\\+|00)33|0)\\s*[1-9](?:[\\s.-]*\\d{2}){4}$'));
            break;
          case 'email':
            validators.push(Validators.pattern('^[a-zA-Z0-9]([-_.]?[a-zA-Z0-9])*@[a-zA-Z0-9]([-.]?[a-zA-Z0-9])*\\.([a-z]{2,20})$'));
            break;
          case 'regex':
            validators.push(Validators.pattern(check.parameter));
            break;
          case 'datetime':
            validators.push(Validators.pattern('^([0-2][0-9]|(3)[0-1])\/(((0)[0-9])|((1)[0-2]))\/\\d{4} ([0-1][0-9]|(2)[0-4])(\\:)(([0-5][0-9]))(\\:)(([0-5][0-9]))$'));  // FORMAT DD/MM/YYYY HH:MM:SS
            break;
          case 'date':
            validators.push(Validators.pattern('^([0-2][0-9]|(3)[0-1])\\/(((0)[0-9])|((1)[0-2]))\\/\\d{4}$'));  // FORMAT DD/MM/YYYY
            break;
          case 'time':
            // validators.push(Validators.pattern('^([0-1][0-9]|(2)[0-4])(\\:)(([0-5][0-9]))(\\:)(([0-5][0-9]))$'));  // FORMAT HH:MM:SS
            validators.push(Validators.pattern('^([0-1][0-9]|(2)[0-4])(\\:)(([0-5][0-9]))$'));  // FORMAT HH:MM
            break;
          case 'number_min':
            validators.push(Validators.min(+check.parameter));
            break;
          case 'number_max':
            validators.push(Validators.max(+check.parameter));
            break;
          case 'password':
            validators.push(Validators.pattern('^(?=.*[A-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[*_&,;:!()%$@])\\S{8,}$'));  // min 8 caracters, with miniuscule, majscule, digit and special
            break;
          case 'length_min':
            validators.push(Validators.minLength(+check.parameter));
            break;
          case 'length_max':
            validators.push(Validators.maxLength(+check.parameter));
            break;
          // case 'file_size_max':
          //   validators.push(fileValidator(field, check.type.type, check.parameter));
          //   break;
          // case 'file_size_min':
          //   validators.push(fileValidator(field, check.type.type, check.parameter));
          //   break;
          // case 'file_type':
          //   validators.push(fileValidator(field, check.type.type, check.parameter));
          //   break;
          default:
            break;
        }
      });
      // FORM CONTROL
      group['field.' + field.id] = new UntypedFormControl(null, { validators: validators, updateOn: updateOn });
      // DEFAULT VALUE
      if (field.default_values_to_string) {
        group['field.' + field.id].setValue(field.default_values_to_string);
      }
      // VALUE
      if (values && typeof values['field.' + field.id] !== 'undefined' && values['field.' + field.id] !== '') {  // SET VALUE
        // DATE TIME FORMAT
        if (['datetime'].indexOf(field.type.name) !== -1) {
          if (values['field.' + field.id].match(/^(\d{1,2})\/(\d{1,2})\/(\d{4}) (\d{2}):(\d{2})$/) || values['field.' + field.id].match(/^(\d{1,2})\/(\d{1,2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2})$/)) {
            group['field.' + field.id].setValue(values['field.' + field.id]);
          } else {
            group['field.' + field.id].setValue(moment(values['field.' + field.id]).format('DD/MM/YYYY HH:mm:ss'));
          }
        } else if (['date'].indexOf(field.type.name) !== -1) {
          // console.log(moment(values['field.' + field.id]).format('DD/MM/YYYY'));
          if (values['field.' + field.id].match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/)) {
            group['field.' + field.id].setValue(values['field.' + field.id]);
          } else {
            group['field.' + field.id].setValue(moment(values['field.' + field.id]).format('DD/MM/YYYY'));
          }
        } else if (['time'].indexOf(field.type.name) !== -1) {
          if (values['field.' + field.id].match(/^(\d{1,2}):(\d{1,2})$/) || values['field.' + field.id].match(/^(\d{1,2}):(\d{1,2}):(\d{1,2})$/)) {
            group['field.' + field.id].setValue(values['field.' + field.id]);
          } else {
            group['field.' + field.id].setValue(moment(values['field.' + field.id]).format('HH:mm'));
          }
        } else {
          group['field.' + field.id].setValue(values['field.' + field.id]);
        }
      } else if (field.type.name === 'boolean') {  // BOOLEAN > IF NULL SET TO FALSE
        group['field.' + field.id].setValue(false);
      }
      // ENTITY PARENT VALUE
      if (field.type.name === 'entity') {
        group['field.' + field.id].setValue(entity_id);
      }
      // AUTOCOMPLETE
      // if (field.autocomplete) {
      //   autocomplete[field.autocomplete] = group['field.' + field.id];
      // }
      // TOUCHED --> FOR ACTIVATE VALIDATORS AT DISPLAY
      // group['field.' + field.id].touched = true;
    });
    return new UntypedFormGroup(group);
  }

  /** --------------------------------------------------------------------------------------------------------------- */

  addLoop(form, section: Section, entity_id: string = null) {
    // console.log(form);
    // GET KEY
    let i = 0;
    Object.keys(form.controls).forEach(key => {
      let explode = key.split('.');
      if (explode[1] === 'new') {
        i = +explode[2];
      }
    });
    const loop_key = 'loop.new.' + (i+1);
    // GENERATE LOOP
    const _loop = this.generateLoop(entity_id, section, loop_key, null, true);
    // ADD CONTROL
    form.addControl(loop_key, _loop);
    return loop_key;
  }

  removeLoop(form: UntypedFormGroup, loop_key: string) {
    form.removeControl(loop_key);
    // delete form.controls[loop];
  }

}
