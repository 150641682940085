import {FormGroup} from "@angular/forms";
import {Functions} from "../_functions/functions";
import {Action} from "./action.model";
import {Detail} from "./detail.model";
import {Value} from "./value.model";
import {int} from "flatpickr/dist/utils";
import {Grid} from "./grid.model";
import {Element} from "./element.model";

export class GridListRow {
  unique_id: string;
  ressource_id: string;
  //
  grids: Grid[] = [];
  details: {} = {};
  actions: Action[];
  //
  action_active: Action;
  // FILTER
  search: string;
  sort: string;
  // LOADING
  loading: boolean = false;
  loading_refresh: boolean = false;

  constructor(datas, element: Element) {
    // RESSOURCE ID
    this.ressource_id = datas['entity_id'];
    this.unique_id = 'grid_row.' + element.unique_id.replace('element.', '') + '#' + this.ressource_id;
    // UPDATE
    this.update(datas,element);
  }

  update(datas, element: Element = null) {
    delete datas['ressource_id'];
    delete datas['unique_id'];

    // DETAILS
    // console.log(datas);
    // console.log(element);
    Object.keys(datas.details).forEach(key_detail => {
      let details = [];
      let main_detail = null;
      Object.keys(datas.details[key_detail]).forEach(key_subdetail => {
        let detail = datas.details[key_detail][key_subdetail];
        // MAIN SUB DETAIL
        if (key_subdetail === key_detail) {
          main_detail = detail;
        }
        details[datas.details[key_detail][key_subdetail].placement ?? key_subdetail] = new Detail(detail, this, this.ressource_id);
        // SEARCH
        if (datas.details[key_detail][key_subdetail].values?.search) {
          // INIT LIST SEARCH
          // if (!list.search_columns.includes(element.details[key][sub_key].id)) {
          //   list.search_columns.push(element.details[key][sub_key].id);
          // }
          // SET ROW SEARCH
          if (!this.search) {
            this.search = '';
          }
          this.search += datas.details[key_detail][key_subdetail].values.search + ' ';
        }
        // SORT
        if (datas.details[key_detail][key_subdetail].values?.sort) {
          // // INIT LIST SORT
          // if (!list.sort_columns[element.details[key][sub_key].id]) {
          //   list.sort_columns[element.details[key][sub_key].id] = 0;
          // }
          // SET ROW SORT
          if (!this.sort) {
            this.sort = '';
          }
          this.sort += datas.details[key_detail][key_subdetail].values.sort + ' ';
        }
        // // ROW DETAIL ACTIONS
        // this.details[detail.id]['actions'] = [];
        // detail?.actions?.forEach(action => {
        //   // TODO
        //   let action_clone = Object.assign(new Action({}, this), action);
        //   action_clone.ressource_id = this.ressource_id;
        //   action_clone.unique_id = 'action.' + action.id + '.' + this.unique_id.replace('row.', '');
        //   // ACTION ABOUT ALL THE LINE
        //   if (element.type.type === 'list' && action.ressource['type']?.type === 'actions') {
        //     action_clone.ressource = this;
        //   }
        //   // VALUES FOR ACTION
        //   if (this.details[detail.id].values['action.' + action.id] !== undefined) {
        //     action_clone.value = this.details[detail.id].values['action.' + action.id][0];
        //   }
        //   this.details[detail.id]['actions'].push(action_clone);
        // });
      });
      this.details[main_detail.placement ?? key_detail] = details;
    });
    // console.log(this.details);

    // // DETAILS
    // if (datas.details) {
    //   this.details = {};
    //   datas.details.forEach(detail => {
    //     this.details[detail.placement ?? detail.order ?? detail.id] = new Detail(detail, element, this.ressource_id);
    //     // SEARCH
    //     if (this.details[detail.placement ?? detail.order ?? detail.id].search) {
    //       if (!this.search) {
    //         this.search = '';
    //       }
    //       this.search += this.details[detail.placement ?? detail.order ?? detail.id].search + ' ';
    //     }
    //     // SORT
    //     if (this.details[detail.placement ?? detail.order ?? detail.id].sort) {
    //       if (!this.sort) {
    //         this.sort = '';
    //       }
    //       this.sort += this.details[detail.placement ?? detail.order ?? detail.id].sort + ' ';
    //     }
    //   });
    // }
    // GRIDS
    this.grids = [];
    datas['grids'].forEach(grid => {
      this.grids.push(new Grid(grid));
    });
    // DETAILS
    // details?.forEach(detail => {
    //   this.details[detail.id] = {};
    //   this.details[detail.id]['values'] = {};
    //   // ROW DETAIL VALUE
    //   if (datas[detail.id]) {
    //     // VALUE
    //     Object.keys(datas[detail.id]).forEach(k => {
    //       this.details[detail.id]['values'][k] = new DetailValue(datas[detail.id][k]);
    //       // SEARCH
    //       if (datas[detail.id][k].search) {
    //         console.log(datas[detail.id][k].search);
    //         this.search += Functions.slugify(datas[detail.id][k].search, true).toLowerCase() + ' ';
    //       }
    //       // SORT
    //       // if (datas[detail.id][k].sort) {
    //       //   console.log(datas[detail.id][k].search);
    //       //   this.search += Functions.slugify(datas[detail.id][k].search, true).toLowerCase() + ' ';
    //       // }
    //       // FILTER
    //     });
    //   }
    //   // ROW DETAIL ACTIONS
    //   this.details[detail.id]['actions'] = [];
    //   detail?.actions?.forEach(action => {
    //     let action_clone = Object.assign(new Action(), action);
    //     action_clone.id = action_clone.id + '.' + this.entity_id;
    //     this.details[detail.id]['actions'].push(action_clone);
    //   });
    // });
    // console.log(this);
  }

  /* ------------------------------------------------------------------------------------------------------------------ */

  delete() {
    //
    this.action_active = null;
    //
    this.grids?.forEach(item => {
      item.delete();
    });
    this.grids = null;
    //
    Object.keys(this.details)?.forEach(item => {
      this.details[item].delete();
    });
    this.details = null;
    //
    this.actions?.forEach(item => {
      item.delete();
    });
    this.actions = null;
  }

}
