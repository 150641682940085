import {Component, Input, OnInit} from '@angular/core';
import {AppService} from "../../../_services/app.service";

@Component({
    selector: 'manager-loader-wrapper-global',
    templateUrl: './loader_wrapper_global.component.html',
    standalone: false
})
export class LoaderWrapperGlobalComponent {

  constructor() {}
}
